<template>
  <div class="update-accounts">
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin tài khoản kế toán'">
          <template v-slot:toolbar>
            <div class="row">
              <div>
                <b-button
                  size="sm"
                  variant="primary"
                  style="margin-right: 10px"
                  @click="addSubAccount"
                >
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                  <b>Thêm mới</b>
                </b-button>
              </div>
            </div>
          </template>
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row">
              <b-container class="bv-example-row">
                <b-row>
                  <b-col cols="6" md="4">
                    <b-form-group id="input-group-name">
                      <label for="input-name">Loại tài khoản:</label>
                      <b-form-select
                        id="select-store"
                        required
                        v-model="selectedType"
                        aria-describedby="input-store-live-feedback"
                        :options="typeOptions"
                        size="sm"
                        class="select-style"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >Chọn tài khoản kế toán</b-form-select-option
                          >
                        </template>
                        <b-form-invalid-feedback id="input-type-feedback"
                          >Vui lòng nhập loại tài
                          khoản.</b-form-invalid-feedback
                        >
                      </b-form-select>
                    </b-form-group>

                    <div
                      v-if="
                        selectedType != PAYMENT_TYPE.TRANSFER &&
                          selectedType != PAYMENT_TYPE.CREDIT
                      "
                    >
                      <b-form-group id="input-group-name">
                        <label for="input-name">Cửa hàng:</label>
                        <Autosuggest
                          :model="searchStore"
                          :suggestions="filteredOptionsStore"
                          placeholder="cửa hàng"
                          :limit="10"
                          @select="onSelectedStore"
                          @change="onInputChangeStore"
                          suggestionName="suggestionName"
                        />
                      </b-form-group>
                    </div>

                    <b-form-group id="input-group-code">
                      <label for="input-code">Tài khoản cha:</label>
                      <Autosuggest
                        :model="selectedParent"
                        :suggestions="filteredOptions"
                        placeholder="sản phẩm cha"
                        :limit="10"
                        @select="onSelected"
                        @change="onInputChange"
                      >
                        <template #custom="{suggestion}">
                          <div>
                            <span>{{ suggestion.item }}</span>
                          </div>
                        </template>
                      </Autosuggest>
                    </b-form-group>

                    <b-form-group id="input-group-code">
                      <label for="input-code">Mã tài khoản:</label>
                      <b-form-input
                        size="sm"
                        id="input-code"
                        v-model="code"
                        placeholder="Nhập mã"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-code">
                      <label for="input-code">Tên tài khoản:</label>
                      <b-form-input
                        size="sm"
                        id="input-code"
                        v-model="name"
                        placeholder="Nhập tên"
                      ></b-form-input>
                    </b-form-group>

                    <div
                      v-if="
                        selectedType == PAYMENT_TYPE.TRANSFER ||
                          selectedType == PAYMENT_TYPE.CREDIT
                      "
                    >
                      <b-form-group id="input-acc-gateway">
                        <label for="input-gateway">Gateway:</label>
                        <b-form-input
                          size="sm"
                          id="input-gateway"
                          v-model="accountGateway"
                          placeholder="Nhập gateway"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group id="input-acc-bank-no">
                        <label for="input-acc-bank-no">Số tài khoản:</label>
                        <b-form-input
                          size="sm"
                          id="input-acc-bank-no"
                          v-model="accountBankNo"
                          placeholder="Nhập số tài khoản"
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <b-form-group id="input-group-name">
                      <label for="input-name">Trạng thái:</label>
                      <b-form-select
                        v-model="selectedStatus"
                        :options="statusOptions"
                        aria-describedby="input-active-live-feedback"
                        size="sm"
                        class="select-style"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="8"
                    v-if="
                      selectedType == PAYMENT_TYPE.TRANSFER ||
                        selectedType == PAYMENT_TYPE.CREDIT
                    "
                  >
                    <b-form-group>
                      <h6>Danh sách tài khoản định danh</h6>
                      <b-table
                        id="sub-account-table"
                        class="table-bordered table-hover col-md-12"
                        :fields="subAccountFields"
                        :items="subAccounts"
                        :per-page="perPage"
                        small
                        :current-page="currentPage"
                      >
                        <template v-slot:cell(identifyCode)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style"
                            v-model="row.item.identifyCode"
                            placeholder="Nhập mã định danh"
                          ></b-input>
                        </template>

                        <template v-slot:cell(storeId)="row">
                          <Autosuggest
                            :model="row.item.storeName"
                            :suggestions="filteredStores"
                            placeholder="cửa hàng"
                            :limit="10"
                            @select="onSelectSubStore(row.item, $event)"
                            @change="onChangeSubStore"
                          />
                        </template>

                        <template v-slot:cell(status)="row">
                          <span v-if="row.item.status === 1">
                            Kích hoạt
                          </span>
                          <span v-else>
                            Không kích hoạt
                          </span>
                        </template>

                        <template v-slot:cell(actions)="row">
                          <v-icon
                            small
                            class="text-danger text-center"
                            @click="deleteItemInArray(row.item.storeId)"
                            v-b-tooltip
                            title="Xóa"
                            :disabled="row.item.used"
                            >mdi-delete {{ row.item.used }}</v-icon
                          >
                        </template>
                      </b-table>

                      <b-row>
                        <b-col>
                          <p class="mt-3 text-dark" style="font-weight: 500">
                            Tổng số: {{ totalItem }}
                          </p>
                        </b-col>
                        <div class="d-flex justify-content-end">
                          <b-col>
                            <b-pagination
                              v-model="currentPage"
                              :total-rows="rows"
                              :per-page="perPage"
                              aria-controls="sub-account-table"
                            ></b-pagination>
                          </b-col>
                        </div>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
            </b-form>
          </template>
          <template v-slot:foot>
            <b-container class="bv-example-row">
              <b-row>
                <b-col>
                  <b-col>
                    <b-button
                      style="fontweight: 600; width: 70px"
                      variant="primary"
                      size="sm"
                      type="submit"
                      @click="onSubmit"
                      >Lưu</b-button
                    >
                    <router-link to="/accounting/accounts" tag="button">
                      <b-button
                        style="margin-left: 10px; font-weight: 600; width: 70px"
                        variant="secondary"
                        size="sm"
                        >Hủy</b-button
                      >
                    </router-link>
                  </b-col>
                </b-col>
                <b-col></b-col>
                <b-col></b-col>
                <b-col></b-col>
              </b-row>
            </b-container>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { removeAccents } from '@/utils/common';
import ApiService from '@/core/services/api.service';
import { getToastInstance } from '@/utils/toastHelper';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { PAYMENT_TYPE } from '@/utils/enum';
import { map } from 'lodash';

export default {
  data() {
    return {
      totalItem: 0,
      perPage: 10,
      PAYMENT_TYPE,
      limit: 10,
      filteredStores: [],
      selectedSubStore: '',
      stores: [],
      subAccounts: [],
      subAccountFields: [
        {
          key: 'id',
          label: 'Mã',
          tdClass: 'td-code',
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'identifyCode',
          label: 'Mã định danh',
          sortable: false,
        },
        {
          key: 'storeId',
          label: 'Cửa hàng',
          sortable: false,
        },
        {
          key: 'updatedBy',
          label: 'Người sửa',
          sortable: false,
        },
        {
          key: 'updatedAt',
          label: 'Ngày sửa',
          sortable: false,
          thStyle: { width: '12%' },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      currentPage: 1,
      listAccSearch: [],
      selectedParent: '',
      options: [
        {
          data: [],
        },
      ],
      filteredOptions: [],
      selectedStore: '',
      parentId: '',
      code: '',
      name: '',
      accountGateway: '',
      accountBankNo: '',
      selectedStatus: 1,
      statusOptions: [
        { value: '1', text: 'Kích hoạt' },
        { value: '2', text: 'Không kích hoạt' },
      ],
      selectedType: 1,
      typeOptions: [
        { value: '1', text: 'Tài khoản tiền mặt' },
        { value: '2', text: 'Tài khoản ngân hàng' },
        { value: '3', text: 'Tài khoản quẹt thẻ' },
        { value: '4', text: 'Tài khoản trả góp' },
      ],
      optionsStore: [],
      filteredOptionsStore: [],
      searchStore: '',
    };
  },
  components: {
    KTCodePreview,
    Autosuggest,
  },
  mounted() {
    this.id = this.$route.query.id;
    this.fetchStore();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kế toán', route: '/accountants' },
      { title: 'Cập nhật tài khoản kế toán', route: '/accountants' },
    ]);
  },
  computed: {
    rows() {
      return this.subAccounts.length;
    },
    selectedClassParent() {
      const acc = this.listAccSearch.find(
        (p) => p.name === this.selectedParent,
      );
      if (acc) {
        return acc.id;
      } else {
        return null;
      }
    },
  },
  created() {
    this.searchParent();
    this.getAccountantById();
  },
  methods: {
    onSelectSubStore(selectedItem, selectedStore) {
      const subAcc = this.subAccounts.find(
        (item) => item.storeId === selectedStore.item.id,
      );
      if (subAcc) {
        return this.makeToastFailure(
          `Cửa hàng ${selectedStore.item.name} đã được thiết lập mã định danh`,
        );
      }
      selectedItem.storeId = selectedStore.item.id;
    },
    onChangeSubStore(text) {
      const filteredData = this.stores
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredStores = [...filteredData];
    },
    deleteItemInArray(storeId) {
      //  update list reason
      for (let i = 0; i < this.subAccounts.length; i++) {
        if (this.subAccounts[i].storeId === storeId) {
          this.subAccounts.splice(i, 1);
        }
      }
    },
    validate() {
      let result = true;
      if (!this.subAccounts.length) return true;
      this.subAccounts.forEach((subAccount) => {
        if (
          subAccount.identifyCode.trim() === '' ||
          subAccount.identifyCode.length < 3
        ) {
          this.makeToastFailure(
            'Vui lòng nhập mã định danh với ít nhất 3 kí tự',
          );
          result = false;
        }

        if (subAccount.identifyCode.length >= 15) {
          this.makeToastFailure('Vui lòng nhập mã định danh < 15 kí tự');
          result = false;
        }

        if (!subAccount.storeId) {
          this.makeToastFailure('Vui lòng chọn cửa hàng');
          result = false;
        }
      });

      return result;
    },
    addSubAccount() {
      // validate data
      const validate = this.validate();
      if (!validate) return;
      // add new sub account
      const subAccount = {
        accountantId: this.id,
        identifyCode: '',
        storeId: null,
        status: 1,
        used: false,
      };

      this.subAccounts.push(subAccount);
    },
    ...getToastInstance(),
    getAccountantById: async function() {
      await ApiService.setHeader();
      await ApiService.get('accountants/' + this.id).then((res) => {
        const { data } = res.data;
        this.selectedParent = data.parentName;
        this.selectedClassParent = data.parentId;
        this.selectedType = data.type;
        this.selectedStore = data.storeId;
        this.code = data.code;
        this.name = data.name;
        this.selectedStatus = data.status;
        this.searchStore = data.storeName;
        this.accountGateway = data.accountGateWay;
        this.accountBankNo = data.accountBankNo;
        if (data.subAccounts.length) {
          this.totalItem = data.subAccounts.length;
          data.subAccounts = data.subAccounts.map((item) => {
            if (String(item.id).length < 4) {
              item.id = String(item.id).padStart(4, 0);
            }
            return {
              ...item,
            };
          });
        }
        this.subAccounts = data.subAccounts;
      });
    },
    onInputChange(text) {
      this.selectedParent = text;

      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            removeAccents(item)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [...filteredData];
    },
    onSelected(option) {
      this.parentId = option.item;
      this.selectedParent = option.item;
    },
    hasDuplicates(arr) {
      return new Set(arr).size !== arr.length;
    },
    validateUpdateData() {
      if (!this.selectedType) {
        this.makeToastFailure('Vui lòng chọn loại tài khoản');
        return false;
      }
      const storeIds = map(this.subAccounts, 'storeId');
      const hasDuplicates = this.hasDuplicates(storeIds);
      if (hasDuplicates) {
        this.makeToastFailure('Cửa hàng đã được thiết lập mã định danh');
        return false;
      }

      if (this.subAccounts.length) {
        for (const subAccount of this.subAccounts) {
          if (!subAccount.identifyCode) {
            this.makeToastFailure('Vui lòng nhập mã định danh');
            return false;
          }
          if (subAccount.identifyCode.length >= 15) {
            this.makeToastFailure('Vui lòng nhập mã định danh < 15 kí tự');
            return false;
          }

          if (!subAccount.storeId) {
            this.makeToastFailure('Vui lòng nhập cửa hàng');
            return false;
          }
        }
      }

      return true;
    },
    onSubmit: async function() {
      if (this.searchStore.trim() === '') {
        this.selectedStore = null;
      }
      const isValidData = this.validateUpdateData();
      if (!isValidData) return;
      let params = {
        id: this.id,
        storeId: this.selectedStore,
        type: this.selectedType,
        parentId: this.selectedClassParent,
        code: this.code,
        name: this.name,
        accountGateway: this.accountGateway,
        accountBankNo: this.accountBankNo,
        status: this.selectedStatus,
        subAccounts: this.subAccounts,
      };
      ApiService.put('accountants', params)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            this.$router.push({
              name: 'list-accountant',
              params: { createAccountantSuccess: true },
            });
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    },
    fetchStore: async function() {
      ApiService.setHeader();
      ApiService.get(
        `stores?name=${this.search || ''}&limit=1000&page=${this.page || 1}`,
      ).then((response) => {
        response.data.data.list_store.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.optionsStore.push(store);
          this.stores.push({
            id: element.id,
            name: element.name,
          });
        });
        this.filteredOptionsStore = [...this.optionsStore];
        this.filteredStores = [...this.optionsStore];
      });
    },
    searchParent: async function() {
      this.listAccSearch = [];
      ApiService.setHeader();
      ApiService.query('accountants/getAll').then((response) => {
        this.options[0].data = [];
        response.data.data.forEach((item) => {
          let acc = {
            id: item.id,
            name: item.name,
            code: item.code,
          };
          this.listAccSearch.push(acc);
          this.options[0].data.push(acc.name);
        });
      });
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.selectedStore = option.item.id;
    },
    onInputChangeStore(text) {
      this.searchStore = text;
      const filteredData = this.optionsStore
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsStore = [...filteredData];
    },
  },
};
</script>

<style lang="scss">
.update-accounts {
  .center-label {
    label {
      margin-bottom: 0px;
    }
  }
  .td-code {
    text-align: center;
    vertical-align: middle;
  }
}
</style>
